import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DefaultDataService, HttpUrlGenerator, QueryParams } from '@ngrx/data';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Station, StationResponse } from '../models';
import { datesUpdate } from '../store/actions';
import { FORECAST_API_URL } from '@cityair/modules/forecast/constants';

@Injectable({
    providedIn: 'root',
})
export class StationDataService extends DefaultDataService<Station> {
    private accessToken = '';
    private defaultParams: QueryParams = {
        interval: 'Interval1h',
    };

    urlPath = this.urlGenerator.collectionResource('Station', '');

    constructor(http: HttpClient, private urlGenerator: HttpUrlGenerator, private store: Store) {
        super('Station', http, urlGenerator, {
            root: FORECAST_API_URL,
        });
    }

    getAll(): Observable<Station[]> {
        throw new Error('not implemented');
    }

    getById(id: string | number): Observable<Station> {
        throw new Error('not implemented');
    }

    getWithQuery(params: QueryParams): Observable<Station[]> {
        const url = this.urlPath + params.group_id + '/Timeline/';
        const mergeParams = Object.assign({}, this.defaultParams, params);
        const token = this.getAccessToken();
        const httpOptions = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: mergeParams,
        };

        return this.http.get<StationResponse>(url, httpOptions).pipe(
            tap((response) => {
                if (response?.meta?.extra?.dates) {
                    this.store.dispatch(datesUpdate({ payload: response.meta.extra.dates }));
                }
            }),
            map((response) => response.data)
        );
    }

    private getAccessToken() {
        return this.accessToken || (this.accessToken = localStorage.getItem('token'));
    }
}
