import { AqiType } from '../enums/aqi.type';
import { AVAILABLE_AQIS } from './avaliable-aqi.const';
import { MMT_WITH_PDK_SORTED } from './mmt-with-pdk-sorted.conts';
import { AQI, TEMP, PRES, HUM } from './substance.consts';

export const MEASUREMENTS_ORDER = [
    AQI,
    AqiType.indoor,
    ...AVAILABLE_AQIS,
    ...MMT_WITH_PDK_SORTED,
    TEMP,
    PRES,
    HUM,
];
